import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  BsBookHalf,
  BsFillGrid3X3GapFill,
  BsFillPatchQuestionFill,
  BsFillPeopleFill,
  BsPersonLinesFill,
} from 'react-icons/bs';
import { BiLogOut, BiSolidBook, BiSolidBookOpen } from 'react-icons/bi';
import { FaBell, FaQuestionCircle, FaUserCog } from 'react-icons/fa';

function LoginSideNav() {
  const handleLogout = () => {
    // Clear all storage
    localStorage.clear();

    // Redirect to the login page
    window.location.href = '/';
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <NavLink exact to="/" className="nav-link">
            <BsFillGrid3X3GapFill size={20}/>
            <span style={{ padding: '5px' }}>Login</span>
          </NavLink>
        </li>
        

        <li className="nav-item">
          <NavLink exact to="/sign-up" className="nav-link collapsed">
            <FaUserCog size={20}/>
            <span style={{ padding: '5px' }}>Sign-up</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink exact to="/registration" className="nav-link collapsed">
            <BsFillPatchQuestionFill size={20}/>
            <span style={{ padding: '5px' }}>Register</span>
          </NavLink>
        </li>
        <li className="nav-item">
      <NavLink exact to="/faqs" className="nav-link collapsed" >
          <FaQuestionCircle size={20}/>
          <span style={{padding: '5px'}}>FAQs</span>
        </NavLink>
      </li>
  
      </ul>
    </aside>
  );
}

export default LoginSideNav;