import React from 'react'
import {NavLink} from 'react-router-dom'

import {BsFillGrid3X3GapFill} from 'react-icons/bs'
import {BiLogOut} from 'react-icons/bi'
import { FaQuestionCircle, FaUserCog} from 'react-icons/fa'

function SideNav() {
  const handleLogout = () => {
    // Clear all storage
    localStorage.clear();

    // Redirect to the login page
    window.location.href = '/';
  };
  return (
    <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
      <NavLink exact to="/studentDashboard" className="nav-link " >
          <BsFillGrid3X3GapFill size={20} />
          <span style={{padding: '5px'}}>Dashboard</span>
        </NavLink>
      </li>
     
      <li className="nav-item">
      <NavLink exact to="/studentProfile" className="nav-link collapsed" >
          <FaUserCog size={20}/>
          <span style={{padding: '5px'}}>My Profile</span>
        </NavLink>
      </li>
     


      <li className="nav-item">
        <a className="nav-link collapsed" onClick={handleLogout} >
          <BiLogOut size={20}/>
          <span style={{padding: '5px'}}>Logout</span>
        </a>
      </li>
    </ul>

  </aside>
  )
}

export default SideNav
