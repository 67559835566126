import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorage } from "./localStorage";

const AdminPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'admin';

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

const StudentPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'student' && userInfo.is_registered;

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

const RegPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'student' && !userInfo.is_registered;

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

export { StudentPrivateRoutes, AdminPrivateRoutes, RegPrivateRoutes };
