import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from 'antd';
import Header from '../Header';
import LoginSideNav from '../LoginSidenav';

function Signup() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        email: '',
        phone_number: '',
        whatsapp_number: '',
        id_number: '',
        id_image: null,
        address: ''
    });
    const[loading, setLoading]=useState(false)

    const handleLoginClick = () => {
        navigate('/'); // Navigate to the root path
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value
        }));
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });

        try {
            const response = await axios.post('https://webservice.accsoncall.com/api/accounts/create-account/', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            setLoading(false)
            toast.success("Account created successfully. Your login password has been sent to your email");
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.data) {
                // Access the error message directly
                const errorData = error.response.data;
                const errorMessage = errorData.error || "An unexpected error occurred."; // Fallback message if 'error' key is not present
    
                toast.error(`Failed to submit application: ${errorMessage}`);
            } else {
                toast.error("Failed to submit application due to a network or server error.");
            }
            // toast.error(`Error creating account: ${error.response ? error.response.data : error.message}`);
        }
    };

    return (
        <>
        <Header/>
            <LoginSideNav/>
            <main>
      <div class="container">
        <div className="reg-container">
            <Spin spinning={loading}>
            <header>Sign Up</header>

            <form onSubmit={handleSubmit}>
                <div className="form first">
                    <div className="details personal">
                        <span className="title">Personal Details</span>

                        <div className="fields">
                            <div className="input-field">
                                <label>Title</label>
                                <select name="title" required onChange={handleChange} value={formData.title}>
                                    <option value="" disabled>Select Title</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Prof">Prof</option>
                                </select>
                            </div>
                            <div className="input-field">
                                <label>First Name</label>
                                <input name="first_name" type="text" placeholder="Enter your first name" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>Last Name</label>
                                <input name="last_name" type="text" placeholder="Enter your last name" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>Date of Birth</label>
                                <input name="date_of_birth" type="date" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>Gender</label>
                                <select name="gender" required onChange={handleChange} value={formData.gender}>
                                    <option value="" disabled>Select gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="input-field">
                                <label>Email</label>
                                <input name="email" type="email" placeholder="Enter your email address" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>Mobile Number</label>
                                <input name="phone_number" type="tel" placeholder="Enter mobile number" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>Whatsapp Number</label>
                                <input name="whatsapp_number" type="tel" placeholder="Enter whatsapp number" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>ID Number</label>
                                <input name="id_number" type="text" placeholder="Enter id number" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>ID Image</label>
                                <input name="id_image" type="file" required onChange={handleChange} />
                            </div>
                            <div className="input-field">
                                <label>Residential Address</label>
                                <input name="address" type="text" placeholder="Enter residential address" required onChange={handleChange} />
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="backBtn" onClick={handleLoginClick}>
                                <i className="uil uil-navigator"></i>
                                <span className="btnText">Login</span>
                            </div>

                            <button type="submit" className="submit">
                                <span className="btnText">Submit</span>
                                <i className="uil uil-navigator"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            </Spin>
            <ToastContainer />
        </div>
        </div>
        </main>
        </>
    );
}

export default Signup;