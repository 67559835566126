import React from 'react';
import Header from '../Header';
import SideNav from './Sidenav';
import Img from '../../images/faqs.jpg'
import LoginSideNav from '../LoginSidenav';

function Faqs() {
  return (
    <>
      <Header />
      <LoginSideNav />
      <div class="card mb-3">
        <img src={Img} class="card-img-top" alt="..."/>
        </div>            
        
      <div className="accordion w-100" id="basicAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#basicAccordionCollapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
             How much is your CTA tuition fee and is it charged per semester?
            </button>
          </h2>
          <div
            id="basicAccordionCollapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#basicAccordion"
          >
            <div className="accordion-body">
              <p> Our CTA Phase 2 (1-year CTA programme) is $2 087 for the full academic year. </p>
              <p>The Phase 1 (2-year CTA programme) is $1 830 per year.</p>
              <p>No additional fees are charged. We do not have semesters at Accounts On Call.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#basicAccordionCollapseTwo"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Is the tuition fee required in full on registration?
            </button>
          </h2>
          <div
            id="basicAccordionCollapseTwo"
            className="accordion-collapse collapse "
            aria-labelledby="headingOne"
            data-bs-parent="#basicAccordion"
          >
            <div className="accordion-body">
              <p>Our standard payment structure allows you to pay 30% on registration and the balance payable over 6 months. </p>
              <p>In addition, we offer customised payment plans at no additional charge.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#basicAccordionCollapseThree"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Can I enroll for the 1-Year CTA programme whilst I am working?
            </button>
          </h2>
          <div
            id="basicAccordionCollapseThree"
            className="accordion-collapse collapse "
            aria-labelledby="headingOne"
            data-bs-parent="#basicAccordion"
          >
            <div className="accordion-body">
              <p>At AOC, we do not differentiate between part time and full time. You can enroll for
                    both; the 1-Year CTA Programme (Phase 2 class) and the 2-Year CTA Programme
                    (Phase 1 class) even if you are going to work. </p>
              <p>All our classes are after hours, on weekends and/or on public holidays.</p>
              <p>All our classes are recorded and are accessible on MyAOC after class.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#basicAccordionCollapseFour"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Is the CTA certificate I get after studying with AOC recognised?
            </button>
          </h2>
          <div
            id="basicAccordionCollapseFour"
            className="accordion-collapse collapse "
            aria-labelledby="headingOne"
            data-bs-parent="#basicAccordion"
          >
            <div className="accordion-body">
              <p>Yes, students from all the ICAZ-accredited CTA service providers receive the same
              certificate issued by ICAZ upon successful completion of Phase 2.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#basicAccordionCollapseFive"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Can I enroll with AOC if I am in a remote area or I’m not in Zimbabwe?
            </button>
          </h2>
          <div
            id="basicAccordionCollapseFive"
            className="accordion-collapse collapse "
            aria-labelledby="headingOne"
            data-bs-parent="#basicAccordion"
          >
            <div className="accordion-body">
              <p>Yes, you can. At AOC we have a blend of online and physical lessons.</p>
              <p>You are able to join our physical sessions online, from anywhere in the world, as long
              as you have internet connectivity.</p>
              <p>For exams we can arrange for the exams to be written in the foreign country, provided the exam conditions are met.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs;
