import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Config from '../../config/config'
import Img from '../../images/bg.jpg'
import { PiUsersFourDuotone } from "react-icons/pi";
import { FaUsers, FaFileAlt, FaCalendarAlt, FaLaptopHouse, FaUsersCog, FaCheckSquare } from 'react-icons/fa';

function Dashboard() {
  const [dashboardStats, setDashboardStats]=useState([])
  const config = Config()
  useEffect(() => {
    getDashboardStats()
  }, [])
  const userID = localStorage.userID
  

  const getDashboardStats = () => {
    const url = `${config.base_url}instructor_dashboard_stats/${userID}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setDashboardStats(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };

  const features = [
    {
      icon: <FaUsers />,
      text: 'Designed to provide a comprehensive and innovative learning experience.',
    },
    {
      icon: <FaFileAlt />,
      text: 'Keen focus on accessibility and flexibility. The programme does not distinguish between part-time or full-time.',
    },
    {
      icon: <FaCalendarAlt />,
      text: 'All our classes are delivered after working hours, on weekends or on public holidays.',
    },
    {
      icon: <FaLaptopHouse />,
      text: 'Offered through a blended learning model, merging the benefits of physical and online contact sessions.',
    },
    {
      icon: <FaUsersCog />,
      text: 'Provides a dynamic and engaging learning environment that accommodates the diverse schedules of our students.',
    },
    {
      icon: <FaCheckSquare />,
      text: 'Consistent assessments leading up to the final examinations ensure that students are continuously engaged.',
    },
  ];
  return (
  <>

<section className="dash-img">
<div class="card mb-3">
  <img src={Img} class="card-img-top" alt="..."/>
  <div class="card-body">
    
    <h5 class="card-title">About AOC</h5>
    <p class="card-text text-align-center">Accounts On Call Institute (Private) Limited stands as a premier institution dedicated to providing exceptional education to students on their journey to becoming Chartered Accountants in Zimbabwe. Established in July 2021 by a passionate and seasoned Chartered Accountant, our institute takes pride in offering Certificate in Theory of Accounting (CTA) tuition, accredited by the Institute of Chartered Accountants of Zimbabwe (ICAZ). We also specialise in providing comprehensive support classes for students looking to sit for the ICAZ Initial Test of Competence (ITC) Exam (AOC Board Course) within the Chartered Accountancy pathway.
</p>

    <h5 class="card-title">Our CTA Programme</h5>
    <div className="row">
        {features.map((feature, index) => (
          <div className="col-md-6 mb-3" key={index}>
            <div className="d-flex align-items-start">
              <div className="text-success me-2" style={{ fontSize: '24px' }}>
                {feature.icon}
              </div>
              <p className="card-text mb-0">{feature.text}</p>
            </div>
          </div>
        ))}
      </div>
      <h5 class="card-title">APPLICATION STATUS: <b>Pending</b></h5>
    </div>

    
</div>

</section>


  </>
  )
}

export default Dashboard
