// import { getLocalStorage } from '@/utils'

import { getLocalStorage } from "../utils/localStorage"

const apiVersion = '/api/'
const mediaPath = '/media/'
const url =
  process.env.NODE_ENV === 'production'
    ? ' https://webservice.accsoncall.com'
    : ' https://webservice.accsoncall.com'
   
const baseUrl = url + apiVersion
const mediaUrl =url + mediaPath
const accessToken = getLocalStorage("userInfo").loginToken;
export default function Config() {
  const config = {
    base_url: baseUrl,
    main_url: url,
    media_url: mediaUrl,
    token: accessToken,
  }
  return config
}
