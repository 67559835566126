import React, { useState } from 'react';
import { Modal, Form, Spin } from 'antd';
import Config from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import setLocalStorage from '../utils/localStorage';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const config = Config();
  const navigate = useNavigate();  // Use the useNavigate hook

  const triggerToast = () => {
    toast("Invalid email or password!");
  };

  async function handleLogin(values) {
    setLoginLoading(true);
    const payload = {
      email: values.email,
      password: values.password,
    };
    const url = `${config.base_url}token/`;

    axios
      .post(url, payload)
      .then((res) => {
        if (res.data.error) {
          setIsLoginLoading(false);
          triggerToast();
        }

        if (res.status === 200) {
          console.log('setting local storage')
          setIsLoginLoading(false);
          localStorage.setItem('accessToken', JSON.stringify(res.data.access));
          setLocalStorage("userInfo", {
            loginToken: res.data.access,
            user_id: res.data.user_id,
            user_type: res.data.user_type,
            is_registered: res.data.is_registered,
            isLoggedIn: true,
          });

          if (res.data.user_type === 'admin') {
            navigate('/dashboard');  
          } else if (res.data.user_type === 'student' && res.data.is_registered) {
            navigate('/studentDashboard');  
          }else if (res.data.user_type === 'student' && !res.data.is_registered) {
            navigate('/registration'); 
          }
           else {
            navigate('/sign-up');  
          }

          setIsLoginLoading(false);
        } else if (res.status === 401) {
          setIsLoginLoading(false);
          triggerToast();
        }
      })
      .catch((error) => {
        setIsLoginLoading(false);
        if (error.response && error.response.status === 401) {
          triggerToast();
        }
      });
  }

  return (
    <>  
      <ToastContainer />
      <main>
        <div className="container">
          <section className="">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                  <div className="d-flex justify-content-center py-4">
                    <a href="/" className="logo d-flex align-items-center w-auto">
                      <img src="assets/img/logo.png" alt=""/>
                    </a>
                  </div>

                  <div className="card mb-3">

                    <div className="card-body">

                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                        <p className="text-center small">Enter your username & password to login</p>
                      </div>

                      <Spin spinning={loginLoading}>
                        <Form className="row g-3 needs-validation" 
                          name="loginForm"
                          onFinish={handleLogin}
                          autoComplete="off"
                        >
                          <div className="col-12">
                            <label htmlFor="email" className="form-label">Password</label>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter email'
                                }
                              ]}
                            >
                              <input type="email" className="form-control" id="email" />
                            </Form.Item>
                            <div className="invalid-feedback">Please enter your email!</div>
                          </div>

                          <div className="col-12">
                            <label htmlFor="yourPassword" className="form-label">Password</label>
                            <Form.Item
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter password'
                                }
                              ]}
                            >
                              <input type="password" className="form-control" id="yourPassword" />
                            </Form.Item>
                            <div className="invalid-feedback">Please enter your password!</div>
                          </div>

                          <div className="col-12">
                            <button className="btn btn-primary w-100" type="submit">Login</button>
                          </div>
                        </Form>
                      </Spin>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default Login;
