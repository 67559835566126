import React, { useEffect, useState } from 'react'
import Header from '../Header'
import SideNav from './Sidenav'
import Img from '../../images/logo.png'
import Config from '../../config/config';
import { Spin } from 'antd';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Notifications } from '../../utils';
function Profile() {
  const config = Config();
  const [profileData, setProfileData] = useState({
    title: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    whatsapp_number: '',
    date_of_birth: '',
    email: '',
    address: '',
  });
  const [passwords, setPasswords] = useState({
    old_password: '',
    new_password: '',
    confirm_password: ''
  });
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({ ...profileData });
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    setLoading(true);
    let url = `${config.base_url}accounts/profile/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setProfileData(res.data);
          setEditData(res.data); 
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to load profile data.");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let url = `${config.base_url}accounts/profile/`;
    axios
      .put(url, editData, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setProfileData(res.data); 
          toast.success('Profile Updated Successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to update profile.");
      });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
  
    if (passwords.new_password !== passwords.confirm_password) {
      toast.error('New passwords do not match.');
      return;
    }
  
    setLoading(true);
    let url = `${config.base_url}accounts/change-password/`;
    axios
      .post(
        url,
        {
          old_password: passwords.old_password,
          new_password: passwords.new_password,
          confirm_password: passwords.confirm_password,
        },
        headers
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          toast.success('Password changed successfully');
          setPasswords({
            old_password: '',
            new_password: '',
            confirm_password: '',
          });
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error.response && error.response.data && error.response.data.errors) {
          const errorMessages = error.response.data.errors;
          
          for (const field in errorMessages) {
            if (errorMessages.hasOwnProperty(field)) {
              toast.error('error', 'Error', `${field}: ${errorMessages[field].join(' ')}`);
            }
          }
        } else {
          toast.error('Failed to change password');
        }
      });
  };

  return (
    <div>
      <Header/>
      <SideNav/>
      <ToastContainer />
       <div class="pagetitle">
      <h1>Profile</h1>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Students</li>
          <li class="breadcrumb-item active">Profile</li>
        </ol>
      </nav>
    </div>

    <section class="section profile">
      <div class="row">
        <div class="col-xl-4">

          <div class="card">
            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">

              <img src={Img} alt="Profile" class="rounded-circle"/>
              <h2>Accounts On Call</h2>
              <div class="social-links mt-2">
                <a href="https://www.facebook.com/profile.php?id=61553709699450&mibextid=LQQJ4d" class="facebook"><i class="bi bi-facebook"></i></a>
                <a href="https://www.instagram.com/accsoncall/?igshid=MTk0NTkyODZkYg%3D%3D&utm_source=qr" class="instagram"><i class="bi bi-instagram"></i></a>
                <a href="https://www.linkedin.com/company/accountsoncall/" class="linkedin"><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

        </div>

        <div class="col-xl-8">
          <Spin spinning={loading}>
          <div class="card">
            <div class="card-body pt-3">
              <ul class="nav nav-tabs nav-tabs-bordered">

                <li class="nav-item">
                  <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                </li>

              </ul>
              <div class="tab-content pt-2">

                <div class="tab-pane fade show active profile-overview" id="profile-overview">
                  {/* <h5 class="card-title">About AOC</h5>
                  <p class="small fst-italic">Accounts On Call Institute (Private) Limited stands as a premier institution dedicated to providing exceptional education to students on their journey to becoming Chartered Accountants in Zimbabwe. Established in July 2021 by a passionate and seasoned Chartered Accountant, our institute takes pride in offering Certificate in Theory of Accounting (CTA) tuition, accredited by the Institute of Chartered Accountants of Zimbabwe (ICAZ). We also specialise in providing comprehensive support classes for students looking to sit for the ICAZ Initial Test of Competence (ITC) Exam (AOC Board Course) within the Chartered Accountancy pathway.</p>

                  <h5 class="card-title">Profile Details</h5> */}

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label ">Title</div>
                    <div class="col-lg-9 col-md-8">{profileData.title}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">First Name</div>
                    <div class="col-lg-9 col-md-8">{profileData.first_name}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Surname</div>
                    <div class="col-lg-9 col-md-8">{profileData.last_name}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Phone Number</div>
                    <div class="col-lg-9 col-md-8">{profileData.phone_number}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Whatsapp Number</div>
                    <div class="col-lg-9 col-md-8">{profileData.whatsapp_number}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Date of Birth</div>
                    <div class="col-lg-9 col-md-8">{profileData.date_of_birth}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Email</div>
                    <div class="col-lg-9 col-md-8"> {profileData.email} </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Address</div>
                    <div class="col-lg-9 col-md-8"> {profileData.address} </div>
                  </div>
                </div>

                <div class="tab-pane fade profile-edit pt-3" id="profile-edit">

                  <form onSubmit={handleSubmit}>

                    <div className="row mb-3">
                          <label htmlFor="title" className="col-md-4 col-lg-3 col-form-label">Title</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="title"
                              type="text"
                              className="form-control"
                              id="title"
                              value={editData.title}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label htmlFor="first_name" className="col-md-4 col-lg-3 col-form-label">First Name</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="first_name"
                              type="text"
                              className="form-control"
                              id="first_name"
                              value={editData.first_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label htmlFor="last_name" className="col-md-4 col-lg-3 col-form-label">Surname</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="last_name"
                              type="text"
                              className="form-control"
                              id="last_name"
                              value={editData.last_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label htmlFor="last_name" className="col-md-4 col-lg-3 col-form-label">Phone Number</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="phone_number"
                              type="text"
                              className="form-control"
                              id="phone_number"
                              value={editData.phone_number}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label htmlFor="last_name" className="col-md-4 col-lg-3 col-form-label">Whatsapp Number</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="whatsapp_number"
                              type="text"
                              className="form-control"
                              id="whatsapp_number"
                              value={editData.whatsapp_number}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label htmlFor="last_name" className="col-md-4 col-lg-3 col-form-label">Date of Birth</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="date_of_birth"
                              type="text"
                              className="form-control"
                              id="date_of_birth"
                              value={editData.date_of_birth}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label htmlFor="last_name" className="col-md-4 col-lg-3 col-form-label">Date of Birth</label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="address"
                              type="text"
                              className="form-control"
                              id="address"
                              value={editData.address}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                    

                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Save Changes</button>
                    </div>
                  </form>

                </div>

                <div class="tab-pane fade pt-3" id="profile-change-password">
                  <form onSubmit={handleSubmitPassword}>

                  <div className="row mb-3">
                      <label
                        htmlFor="old_password"
                        className="col-md-4 col-lg-3 col-form-label"
                      >
                        Old Password
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="old_password"
                          type="password"
                          className="form-control"
                          id="old_password"
                          value={passwords.old_password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="new_password"
                        className="col-md-4 col-lg-3 col-form-label"
                      >
                        New Password
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="new_password"
                          type="password"
                          className="form-control"
                          id="new_password"
                          value={passwords.new_password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="confirm_password"
                        className="col-md-4 col-lg-3 col-form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="confirm_password"
                          type="password"
                          className="form-control"
                          id="confirm_password"
                          value={passwords.confirm_password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>

                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Change Password</button>
                    </div>
                  </form>
                </div>

              </div>

            </div>
          </div>
          </Spin>

        </div>
      </div>
    </section>
    </div>
  )
}

export default Profile
